import styled from 'styled-components';

const StyledCircle = styled.div`
  width: 9vw;
  height: 9vw;
  transition: opacity 0.25s;
  background-color: white;
  opacity: ${(props) => (props.active ? 1 : 0.2)};
  border-radius: 50%;
  margin: 1.5vw;

  @media only screen and (orientation: landscape) {
    width: 4.5vw;
    height: 4.5vw;
    margin: 0.5vw;
  }
`;

function Circle({ active }) {
  return <StyledCircle active={active} />;
}

export default Circle;
