import './App.css';
import BinaryBlock from './components/BinaryBlock';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

const StyledApp = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
`;

const Link = styled.a`
  color: white;
  text-decoration: underline;
  margin-left: 8px;
`;

function App() {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    setInterval(() => {
      const now = new Date();
      setHours(now.getHours());
      setMinutes(now.getMinutes());
      setSeconds(now.getSeconds());
    }, 1000);

    // clear interval
  }, []);

  return (
    <>
      <StyledApp>
        <Container>
          <BinaryBlock data={hours} isHours />
          <BinaryBlock data={minutes} />
          <BinaryBlock data={seconds} />
        </Container>
        <Footer>
          Made with ❤️ by : {' '}
          <Link
            href="https://atxyz.dev/"
            target="_blank"
            rel="noreferrer"
          >
            ATXYZ 
          </Link>
        </Footer>
      </StyledApp>
    </>
  );
}

export default App;
