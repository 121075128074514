import Circle from './Circle';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0 1.5vw;

  @media only screen and (orientation: landscape) {
    padding: 0 0.75vw;
  }
`;

const Col1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Col2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  color: white;
  font-size: 8vw;
  margin-top: 1vw;

  @media only screen and (orientation: landscape) {
    font-size: 4vw;
  }
`;

function BinaryBlock({ data, isHours }) {
  const [slot1Active, setSlot1Active] = useState(false);
  const [slot2Active, setSlot2Active] = useState(false);
  const [slot3Active, setSlot3Active] = useState(false);
  const [slot4Active, setSlot4Active] = useState(false);
  const [slot5Active, setSlot5Active] = useState(false);
  const [slot6Active, setSlot6Active] = useState(false);
  const [slot7Active, setSlot7Active] = useState(false);
  const firstCharacter = data.toString().padStart(2, '0')[0];
  const secondCharacter = data.toString().padStart(2, '0')[1];

  useEffect(
    function handleFirstNumber() {
      setSlot1Active(false);
      setSlot2Active(false);
      setSlot3Active(false);

      if (firstCharacter === '5') {
        setSlot1Active(true);
        setSlot3Active(true);
      }

      if (firstCharacter === '4') {
        setSlot1Active(true);
      }

      if (firstCharacter === '3') {
        setSlot2Active(true);
        setSlot3Active(true);
      }

      if (firstCharacter === '2') {
        setSlot2Active(true);
      }

      if (firstCharacter === '1') {
        setSlot3Active(true);
      }
    },
    [firstCharacter]
  );

  useEffect(
    function handleSecondNumber() {
      setSlot4Active(false);
      setSlot5Active(false);
      setSlot6Active(false);
      setSlot7Active(false);

      if (secondCharacter === '9') {
        setSlot4Active(true);
        setSlot7Active(true);
      }

      if (secondCharacter === '8') {
        setSlot4Active(true);
      }

      if (secondCharacter === '7') {
        setSlot5Active(true);
        setSlot6Active(true);
        setSlot7Active(true);
      }

      if (secondCharacter === '6') {
        setSlot5Active(true);
        setSlot6Active(true);
      }

      if (secondCharacter === '5') {
        setSlot5Active(true);
        setSlot7Active(true);
      }

      if (secondCharacter === '4') {
        setSlot5Active(true);
      }

      if (secondCharacter === '3') {
        setSlot6Active(true);
        setSlot7Active(true);
      }

      if (secondCharacter === '2') {
        setSlot6Active(true);
      }

      if (secondCharacter === '1') {
        setSlot7Active(true);
      }
    },
    [secondCharacter]
  );

  return (
    <Wrapper>
      <Col1>
        {!isHours && <Circle active={slot1Active} />}
        <Circle active={slot2Active} />
        <Circle active={slot3Active} />
        <Text>{firstCharacter}</Text>
      </Col1>
      <Col2>
        <Circle active={slot4Active} />
        <Circle active={slot5Active} />
        <Circle active={slot6Active} />
        <Circle active={slot7Active} />
        <Text>{secondCharacter}</Text>
      </Col2>
    </Wrapper>
  );
}

export default BinaryBlock;
